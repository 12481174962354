import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import MainToolbar from "../components/main-toolbar"
import LineTitle from "../components/LineTitle"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Box from "@material-ui/core/Box"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  background: {
    padding: 0,
    backgroundColor: "#202020",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    paddingBottom: theme.spacing(5),
  },
  contactTitle: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "170px",
    ["@media (max-width:780px)"]: {
      paddingTop: "100px",
    },
  },
  contactTitle2: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "40px",
  },
  header: {
    color: "white",
    fontWeight: "bold",
  },
  content: {
    color: "white",
    paddingBottom: "16px",
  },
}))

const Privacy = ({ intl, location }) => {
  const classes = useStyles()
  const form = "https://kooldigital.lpages.co/test-form/"
  const t = (id) => intl.formatMessage({ id })

  const policyData = [
    {
      header: "THE POLICY",
      content:
        "Face Institute is bound by the General Data Protection Regulation GDPR - protection of personal data for private individuals throughout the EU. We at Face Institute (KVK 75270862) respect your privacy and are committed to protecting your personal information. Our privacy policy outlines our approach to privacy and how we collect, use and protect your personal information. It also sets out your rights in relation to accessing the personal information we collect and hold about you.",
    },
    {
      header: "1. REGISTRATION OF PERSONAL DATA",
      content:
        "When you register with us via the online booking form, your contact information is saved in order to be able to efficiently handle your bookings or other existing services with us. If you have separately agreed on receiving newsletters, we send the newsletters to inform you about latest news, offers and current events. You can always unsubscribe from our newsletters by sending us an email or by unsubscribing from the newsletters by clicking “unsubscribe” at the bottom of the newsletter.",
    },
    {
      header: "2. COOKIE POLICY",
      content:
        "When you visit our website, we, and/or third parties, may place cookies on your browser to enhance and manage our website. A cookie is a small text file that contains information and is stored on the visitor's computer. Cookies are used for technical reasons in order for you to be able to use the website in the best way and to register visitor statistics. You can turn off the use of cookies in your browser settings, or remove an existing cookie related to our site. We use Google Analytics cookie to improve our service, usability and to analyze how the website is used. We use Google Ads tracking cookie for ads delivered by Google Ads. We also use Facebook tracking cookie for ads delivered by Facebook. The cookie popup we show on our site also includes a cookie that expires in 100 days. Your acceptance of our Cookie Policy is deemed to occur if you press the Accept cookies button on Our cookie popup. If you do not agree to our Cookie Policy, please stop using this website immediately.",
    },
    {
      header: "3. INTELLECTUAL PROPERTY",
      content:
        "All content, graphics, photographs, videos, sound, logos and computer code used in the website is either owned, controlled, or licensed to Face Institute. We reserve all intellectual property rights of content used on this website.",
    },
    {
      header: "4. PICTURES ON THE WEBSITE AND PUBLICATION ON SOCIAL MEDIA",
      content:
        "We are careful when we publish pictures of individuals on the website or social media. Our policy is that posts that directly identify an individual with name and/or picture may only be made if the person has given their consent to the publication. We often take pictures and in some cases film sequences at the events we organize. These may be published on our website and/or our channels in social media without the consent of everyone seen in the picture. If you want to participate in an event but do not want to be photographed, please inform us in connection with registration or at the event and we will respect your wishes. Our idea when we choose to publish an image is that it should fulfill a purpose and not be perceived as offensive or pointing to any individual or group. If you nevertheless believe that we have made a publication of an image or a post that identifies you personally and that you do not feel comfortable with, contact us and we will immediately remove the image both externally and internally.",
    },
    {
      header: "5. INFORMATION SECURITY",
      content:
        "We always handle data about you in a reliable way and we do not share this information to third parties. However, we may disclose your personal information to suppliers who, for example, provide IT services, media selection or advertising for our business. Recipients who handle personal data on our behalf must always enter into a so-called personal data assistant agreement with us, in order for us to be able to ensure that your data is handled in a correct and secure manner.",
    },
    {
      header: "6. COLLECTION OF DATA WHEN BOOKING A CONSULTATION",
      content:
        "All our bookings are made online and through Clinicminds or Leadpages services. Clinicmids is a Dutch GDPR compliant service and Leadpages commits to the EU Standard Contractual Clauses (SCCs). We are the owners of the collected data and can easily delete all your patient information if requested.",
    },
    {
      header: "7. ACCESS TO INFORMATION",
      content:
        "You can once a year request to receive all information stored by us via a written request at info@faceinstitute.nl or by sending a written request to our clinic: \n\nFace Institute\n\nFrederiksplein 47B\n\n1017 XL AMSTERDAM\n\nIn the request, we want you to state your name, address, e-mail and telephone number in addition to your case. Replies will be sent to your e-mail or population registration addres",
    },
    {
      header: "8. AMENDMENT AND DELETION OF INFORMATION",
      content:
        "In certain circumstances, you have the right to ask us to erase your personal data, for example where the personal data we collected is no longer necessary for the original purpose, where personal data has become obsolete or where you withdraw your consent. However, this will need to be balanced against other factors. For example, we may not be able comply with your request due to certain legal or regulatory obligations.\n\nHow do we use your information?\n\nWe have never and will never pass on your information to third parties. All information you have stored with us is only related to your treatments and for you to be able to receive newsletters.",
    },
    {
      header: "9. WHEN DOES THIS PRIVACY POLICY APPLY?",
      content:
        "Our privacy policy applies to all our registers and services linked to our websites. Our websites may contain links to other websites. When you choose to visit these, our privacy policy no longer applies.",
    },
    {
      header: "10. CONTACT DETAILS AND ADDITIONAL INFORMATION",
      content:
        "If you would like more information about our approach to privacy, or if you wish to contact us regarding the information set out in this policy, please contact us by email at info@faceinstitute.nl or by post at Face Institute, Frederiksplein 47B, 1017 XL AMSTERDAM.",
    },
    {
      header: "11. CHANGE TO THIS POLICY",
      content:
        "We may amend this policy from time to time at our discretion. Amended versions will be posted on our website at faceinstitute.nl\n\nPrivacy Policy last updated in August 2020.",
    },
  ]

  const PolicyPart = ({ header, content }) => {
    return (
      <div>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography component="legend" className={classes.header}>
            {header}
          </Typography>
          <Typography component="legend" className={classes.content}>
            {content}
          </Typography>
        </Box>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Privacy-policy" path={location.pathname} />
      <MainToolbar className={classes.toolbar} />
      <Container maxWidth={"xl"} className={classes.background}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Container>
              <LineTitle
                header={intl.formatMessage({ id: "privacy.header" })}
                subheader={intl.formatMessage({ id: "privacy.subHeader" })}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.formContainer}>
            <Typography
              component="h5"
              variant="p"
              align="center"
              className={classes.contactTitle}
            ></Typography>
          </Grid>
        </Grid>
        <Container maxWidth={"md"}>
          <div style={{ height: 50 }}></div>
          {policyData.map((policy) => (
            <PolicyPart header={policy.header} content={policy.content} />
          ))}
          <div style={{ height: 50 }}></div>
        </Container>
      </Container>
    </Layout>
  )
}

export default injectIntl(Privacy)

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
